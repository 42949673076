import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "INSTAR W2 USB Webcam",
  "path": "/Frequently_Asked_Question/INSTAR_Webcam_W2/",
  "dateChanged": "2021-01-12",
  "author": "Mike Polinowski",
  "excerpt": "I see two notches next to the lense. Is the lense adjustable? And what does the symbol on the right mean? Is that an on/off switch?",
  "image": "../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "USB Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='FAQs - INSTAR W2 USB Webcam' dateChanged='2021-01-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='I see two notches next to the lense. Is the lense adjustable? And what does the symbol on the right mean? Is that an on/off switch?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/INSTAR_Webcam_W2/' locationFR='/fr/Frequently_Asked_Question/INSTAR_Webcam_W2/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "instar-w2-usb-webcam",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#instar-w2-usb-webcam",
        "aria-label": "instar w2 usb webcam permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`INSTAR W2 USB Webcam`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: I see two notches in the `}<a parentName="p" {...{
        "href": "https://www.instar.de/webcams-usb-webcam-1080p-webcam-fullhd-windows-macos-microphone-mikrofon-pc-kamera-camera/in-w2-usb-wecam-1080p-webcam-pc-webcam-web-camera-with-microphone-privacy-bracket-mount.html"
      }}>{`INSTAR W2 USB Webcam`}</a>{` next to the lense. Is the lense adjustable? And what does the symbol on the right mean? Is that an on/off switch?`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: Correct. The two notches on the lense ring can be used to adjust the camera focus.`}</p>
    <p>{`The camera has a lense cover, to protect your privacy. But there are no buttons on the front element. The `}<strong parentName="p">{`Red LED`}</strong>{` on the right lights up as soon as you connect your camera to an USB port on your computer. The `}<strong parentName="p">{`Green LED`}</strong>{` lights up, when a software starts streaming its video feed.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/de27ce8b76a30efbcb2d0fbb020543da/9b204/INSTAR_W2_Webcam.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACOUlEQVQozwXBaU8SAACAYT611spmmYrggaYQIscKEY8MyGsiwbwFhuHByphOR8jmmE2Z2QgFM1FIYBzeljj72k97ex7B+fEPLjJxrvJJzvO/cE3PEo/HuLs94+bPKTc3Bc5yP0nvhEhFv3JytINvcQm73U0hFSWfilJIx8gf75A5CiPI7X7hdD9EMRPhIBzk3oNnzM+4+HeX4e53ir/FLJcnCbKxLU7iYYqFQ8wD7yirkJJP71O8SnF7fcz16T759C6C3M4GkfUNgv4QC55l5E2tWKwOPnr9uD+sEtmNcJkJ8823yJxjhTmXn+5OG81KAyMTc4w5F3B5/CSPopznYgj2AotoNcNUiQ0IRa309zlQawcpq9dTLTNisMySPthk3DyFsKqHh48VKLVDdHXZqGvsorJWjUhqwj3/iVwihGDNNUxdbSdCkRaRqIW+fju9nVbaalSUN+hpUhpZmpmgR2eiTNiBpLKFAXU3PeYZ5E06hDVqyiV62l/34vVMIgiMmTColDTWNSMW1lNSKkIva2HTamHSOY7P6yQceM+0qQONVI6y+jma0iruP6pA90rLqG2QqdFBVuZtbK86EKw5h/CNGxnSNSJrUCOVm5CpTLS+sZD8vsRFIkA06MY72MZcn4qXcjm1UiMvFEakLQYWF6YpZoNk9/0cbS8gCDjMBKbNLDt60DTLqRQ1IJE0olAoiG15OYn72Vt347V289n+lr52NU+eihGJJZSUCpkY6ec0sUoy4uVwy8N/BXxRsaCM8tAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/de27ce8b76a30efbcb2d0fbb020543da/e4706/INSTAR_W2_Webcam.avif 230w", "/en/static/de27ce8b76a30efbcb2d0fbb020543da/d1af7/INSTAR_W2_Webcam.avif 460w", "/en/static/de27ce8b76a30efbcb2d0fbb020543da/7f308/INSTAR_W2_Webcam.avif 920w", "/en/static/de27ce8b76a30efbcb2d0fbb020543da/e1c99/INSTAR_W2_Webcam.avif 1380w", "/en/static/de27ce8b76a30efbcb2d0fbb020543da/76ea5/INSTAR_W2_Webcam.avif 1840w", "/en/static/de27ce8b76a30efbcb2d0fbb020543da/8b17a/INSTAR_W2_Webcam.avif 2520w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/de27ce8b76a30efbcb2d0fbb020543da/a0b58/INSTAR_W2_Webcam.webp 230w", "/en/static/de27ce8b76a30efbcb2d0fbb020543da/bc10c/INSTAR_W2_Webcam.webp 460w", "/en/static/de27ce8b76a30efbcb2d0fbb020543da/966d8/INSTAR_W2_Webcam.webp 920w", "/en/static/de27ce8b76a30efbcb2d0fbb020543da/445df/INSTAR_W2_Webcam.webp 1380w", "/en/static/de27ce8b76a30efbcb2d0fbb020543da/78de1/INSTAR_W2_Webcam.webp 1840w", "/en/static/de27ce8b76a30efbcb2d0fbb020543da/3912a/INSTAR_W2_Webcam.webp 2520w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/de27ce8b76a30efbcb2d0fbb020543da/81c8e/INSTAR_W2_Webcam.png 230w", "/en/static/de27ce8b76a30efbcb2d0fbb020543da/08a84/INSTAR_W2_Webcam.png 460w", "/en/static/de27ce8b76a30efbcb2d0fbb020543da/c0255/INSTAR_W2_Webcam.png 920w", "/en/static/de27ce8b76a30efbcb2d0fbb020543da/b1001/INSTAR_W2_Webcam.png 1380w", "/en/static/de27ce8b76a30efbcb2d0fbb020543da/161ec/INSTAR_W2_Webcam.png 1840w", "/en/static/de27ce8b76a30efbcb2d0fbb020543da/9b204/INSTAR_W2_Webcam.png 2520w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/de27ce8b76a30efbcb2d0fbb020543da/c0255/INSTAR_W2_Webcam.png",
              "alt": "INSTAR W2 USB Webcam",
              "title": "INSTAR W2 USB Webcam",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      